import React from 'react';
import { connect as connectFela } from 'react-fela';
import * as componentStyles from './PrivacyPolicy.styles';
import Heading1 from '../Heading1';
import Text from '../Text';
import Heading2 from '../Heading2';

const PrivacyPolicy = ({ styles }) => {
    return (
        <div className={styles.bgWrapper}>
            <main className={styles.wrapper}>
                <Heading1>ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ</Heading1>
                <Text>
                    Prostřednictvím těchto Zásad ochrany osobních údajů (dále jen „Zásady“) informujeme subjekty údajů,
                    jejichž osobní údaje zpracováváme, o veškerých činnostech zpracování a o zásadách ochrany soukromí
                    subjektů údajů.
                </Text>
                <Heading2>1. Odpovědné osoby</Heading2>
                <Text>
                    Správce osobních údajů: <bold>Ackee, s. r. o.</bold>, IČ: 242 40 826, se sídlem Evropská 2758/11,
                    Dejvice, 160 00 Praha 6 (dále také jen „Společnost“, „my“, „naše“ nebo „nás“)
                </Text>
                <Text>Kontakt pro uplatnění práv v souvislosti s ochranou osobních údajů: info@ackee.cz </Text>
                <Heading2>2. Základní pojmy</Heading2>

                <Text bold>GDPR:</Text>
                <Text>
                    Nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se
                    zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES účinné od
                    25. 5. 2018.
                </Text>

                <Text bold>Osobní údaj:</Text>
                <Text>
                    Osobním údajem se ve smyslu nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických
                    osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice
                    95/46/ES (dále jen „GDPR“) rozumí veškeré informace o identifikované nebo identifikovatelné fyzické
                    osobě, (tj. o subjektu údajů = Vás).
                </Text>

                <Text bold>
                    <br />
                    Zvláštní osobní údaj:
                </Text>
                <Text>
                    Zvláštním osobním údajem se rozumí údaj o rasovém či etnickém původu, politických názorech,
                    náboženském vyznání či filozofickém přesvědčení nebo členství v odborech, a zpracování genetických
                    údajů, biometrických údajů za účelem jedinečné identifikace fyzické osoby a údajů o zdravotním stavu
                    či o sexuálním životě nebo sexuální orientaci fyzické osoby.
                </Text>

                <Text bold>Subjekt údajů = Vy:</Text>
                <Text>
                    Subjektem údajů se myslí identifikovaná nebo identifikovatelná fyzická osoba, přičemž
                    identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat,
                    zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový
                    identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické,
                    ekonomické, kulturní nebo společenské identity této fyzické osoby.
                </Text>

                <Text bold>Zpracování osobních údajů:</Text>
                <Text>
                    Zpracováním osobních údajů se ve smyslu čl. 4 odst. 2 GDPR myslí jakákoliv operace nebo soubor
                    operací s osobními údaji nebo soubory osobních údajů, který je prováděn pomocí či bez pomoci
                    automatizovaných postupů, jako je shromáždění, zaznamenání, uspořádání, strukturování, uložení,
                    přizpůsobení nebo pozměnění, vyhledání, nahlédnutí, použití, zpřístupnění přenosem, šíření nebo
                    jakékoliv jiné zpřístupnění, seřazení či zkombinování, omezení, výmaz nebo zničení.
                </Text>

                <Text bold>Správce:</Text>
                <Text>
                    Správcem Vašich osobních údajů jsme my. Ve smyslu ve smyslu čl. 4 odst. 7 GDPR se správcem rozumí
                    fyzická nebo právnická osoba, orgán veřejné moci, agentura nebo jiný subjekt, který sám nebo
                    společně s jinými určuje účely a prostředky zpracování osobních údajů; jsou-li účely a prostředky
                    tohoto zpracování určeny právem Unie či členského státu, může toto právo určit dotčeného správce
                    nebo zvláštní kritéria pro jeho určení.
                </Text>

                <Text bold>Zpracovatel:</Text>
                <Text>
                    Zpracovatelem se ve smyslu čl. 4 odst. 8 GDPR fyzická nebo právnická osoba, orgán veřejné moci,
                    agentura nebo jiný subjekt, který zpracovává osobní údaje pro správce; tedy i někteří obchodní
                    partneři Společnosti, kteří na pokyn a dle požadavků Společnosti provádějí zpracování osobních
                    údajů.
                </Text>

                <Text bold>Rizikové zpracování:</Text>
                <Text>
                    Rizikovým zpracování se rozumí zpracování, které pravděpodobně představuje riziko pro práva a
                    svobody subjektů údajů, zpracování není příležitostné, nebo zahrnuje zpracování zvláštních kategorií
                    údajů uvedených nebo osobních údajů týkajících se rozsudků v trestních věcech a trestných činů
                    uvedených v článku 10 GDPR.
                </Text>

                <Text bold>Automatizované individuální rozhodování vč. profilování:</Text>
                <Text>
                    Automatizovaným individuálním rozhodováním vč. profilování se obecně rozumí jakákoli forma
                    rozhodnutí založená na automatizovaném zpracování osobních údajů, tj. bez lidského zásahu,
                    spočívajícím mimo jiné na hodnocení některých osobních aspektů vztahujících se k subjektu údajů,
                    zejména za účelem rozboru nebo odhadu resp. analýzy či předvídání aspektů týkajících se jeho
                    pracovního výkonu, ekonomické situace, zdravotního stavu, osobních preferencí, zájmů, spolehlivosti,
                    chování, místa, kde se nachází, nebo pohybu.
                </Text>
                <Heading2>3. Zpracovávané osobní údaje</Heading2>
                <Text>
                    Zpracováváme Vaše identifikační údaje (jméno, příjmení), kontaktní údaje (adresa, e-mail, telefon),
                    lokalizační údaje (GPS), údaje obsažené v životopisech uchazečů o zaměstnání pro účely výběrového
                    řízení, účetní údaje našich dodavatelů (číslo bankovního účtu), historii objednávek a dodávek, údaje
                    o reklamacích. Tyto údaje zpracováváme v souladu s právními předpisy, zejména s GDPR, v platném
                    znění.
                </Text>
                <Heading2>4. Kategorie subjektů údajů</Heading2>
                <Text>Zpracováváme osobní údaje osob rozdělených do následujících kategorií</Text>
                <Text>a) Naši zákazníci (objednavatelé aplikace App4Event)</Text>
                <Text>b) Zákazníci našich zákazníků (uživatelé aplikace App4Event)</Text>
                <Text>c) Naši dodavatelé zboží a služeb</Text>
                <Text>d) Odběratelé novinek</Text>
                <Heading2>5. Účel zpracování osobních údajů</Heading2>
                <Text>Osobní údaje zpracováváme za jednoznačně vymezeným účelem:</Text>

                <div className={styles.tableWrapper}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <td>
                                    <Text bold>Kategorie subjektů údajů</Text>
                                </td>
                                <td>
                                    <Text bold>Účel zpracování osobních údajů</Text>
                                </td>
                                <td>
                                    <Text bold>Právní základ a zpracovávané osobní údaje</Text>
                                </td>
                                <td>
                                    <Text bold>Doba zpracování</Text>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Text bold>Naši zákazníci</Text>
                                </td>
                                <td>
                                    <Text>
                                        Plnění a realizace smluv uzavřených se zákazníky (poskytnutí služeb, vyřizování
                                        reklamací)
                                    </Text>
                                </td>
                                <td>
                                    <Text>Právním základem je plnění objednávky - smlouvy.</Text>
                                    <Text>
                                        Zpracováváme identifikační údaje (jméno, příjmení), kontaktní údaje firmy nebo
                                        jednotlivce (telefonní číslo, e-mailovou adresu a adresu trvalého pobytu /
                                        sídla).
                                    </Text>
                                </td>
                                <td>
                                    <Text>
                                        Za tímto účelem mohou být osobní údaje zpracovávány po dobu trvání smluvního
                                        vztahu.
                                    </Text>
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <Text>
                                        Uplatňování nároků ze smluvních vztahů po ukončení smlouvy (zejména vyřizování
                                        reklamací nebo, vymáhání pohledávek a jiných povinností z uzavřených smluv)
                                    </Text>
                                    <Text>
                                        Šíření obchodních sdělení v podobě emailových newsletterů obsahujících nabídky,
                                        informace a aktuality
                                    </Text>
                                    <Text>Plnění našich povinností v oblasti vedení účetnictví a daní</Text>
                                </td>
                                <td>
                                    <Text>Právním základem je náš oprávněný zájem.</Text>
                                    <Text>Zpracováváme kontaktní údaje (telefon, e-mail).</Text>
                                    <br />
                                    <Text>Právním základem je náš oprávněný zájem.</Text>
                                    <Text>
                                        Ke zpracování identifikačních a kontaktních osobních údajů zákazníků dochází za
                                        účelem šíření obchodních sdělení v souladu se zákonem č. 480/2004 Sb.
                                    </Text>
                                    <Text>
                                        Právním základem je plnění právních povinností, které nám ukládají právní
                                        předpisy jako zákon o účetnictví nebo zákon o dani z přidané hodnoty.
                                    </Text>
                                </td>
                                <td>
                                    <Text>
                                        Za tímto účelem mohou být osobní údaje zpracovávány po dobu čtyř let od ukončení
                                        smluvního vztahu a je-li zahájeno soudní nebo jiné řízení, pak po celou dobu
                                        těchto řízení.
                                    </Text>
                                    <Text>
                                        Za tímto účelem mohou být osobní údaje zpracovávány na dobu neurčitou až do
                                        okamžiku, kdy se příjemce odhlásí z odběru.
                                    </Text>
                                    <Text>
                                        Za tímto účelem mohou být osobní údaje zpracovávány až po dobu 5 let od konce
                                        zdaňovacího období, ve kterém se uskutečnila transakce.
                                    </Text>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text bold>Zákaznící našich zákazníků</Text>
                                </td>
                                <td>
                                    <Text>Provoz aplikace</Text>
                                </td>
                                <td>
                                    <Text>
                                        Aplikace využívá služeb třetích stran pro přihlášení do aplikace (není povinné),
                                        A4E ovšem neuchovává ani dále nedistribuuje uživatlské přihlašovací údaje.
                                    </Text>
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    <Text bold>Naši dodavatelé zboží a služeb</Text>
                                </td>
                                <td>
                                    <Text>
                                        Plnění a realizace smluv uzavřených s dodavateli, externími spolupracovníky a
                                        věřiteli, vymáhání pohledávek
                                    </Text>
                                    <Text>Uplatňování nároků ze smluvních vztahů po ukončení smlouvy</Text>
                                </td>
                                <td>
                                    <ul>
                                        <Text>Právním základem je plnění smlouvy.</Text>
                                        <Text>
                                            Zpracování identifikačních a účetních osobních údajů druhých smluvních
                                            stran je nezbytné pro splnění vzájemných smluvních závazků.
                                        </Text>
                                    </ul>
                                    <ul>
                                        <Text>Právním základem je náš oprávněný zájem.</Text>
                                        <Text>
                                            Shromažďování identifikačních a účetních údajů, mj. o uskutečněných
                                            platbách, je nezbytné pro vyřizování reklamací, vymáhání pohledávek a jiných
                                            smluvních povinností ze smluv uzavřených mezi námi a těmito subjekty údajů.
                                        </Text>
                                    </ul>
                                </td>
                                <td>
                                    <Text>
                                        Za tímto účelem mohou být osobní údaje zpracovávány po dobu trvání smluvního
                                        vztahu.
                                    </Text>
                                    <Text>
                                        Za tímto účelem mohou být osobní údaje zpracovávány po dobu čtyř let od ukončení
                                        smluvního vztahu, v případě sporného řízení po celou dobu řízení.
                                    </Text>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text bold>Odběratelé novinek</Text>
                                </td>
                                <td>
                                    <Text>Pravidelné zasílání obchodních a obdobných sdělení e-mailem</Text>
                                </td>
                                <td>
                                    <Text>
                                        Právním základem je souhlas, který jste nám udělili při registraci k odběru
                                        novinek.
                                    </Text>
                                    <Text>Identifikační údaje (jméno a příjmení), kontaktní údaj (e-mail).</Text>
                                </td>
                                <td>
                                    <Text>
                                        Za tímto účelem mohou být osobní údaje zpracovávány do odvolání souhlasu.
                                    </Text>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Heading2>6. Doba zpracování osobních údajů</Heading2>
                <Text>
                    Osobní údaje uchováváme pouze po dobu, která je nezbytná k účelu jejich zpracování – viz tabulka
                    výše. Po uplynutí této doby mohou být osobní údaje uchovávány pouze pro účely státní statistické
                    služby, pro účely vědecké a pro účely archivnictví.
                </Text>
                <Heading2>7. Příjemci osobních údajů a předání osobních údajů mimo Evropskou unii</Heading2>
                <Text>
                    V odůvodněných případech můžeme předat Vaše osobní údaje i jiným subjektům (dále jen „příjemci“).
                </Text>
                <ul>
                    <li>
                        <Text>Může dojít k předání osobních údajů těmto příjemcům:</Text>
                    </li>
                    <li>
                        <Text>
                            zpracovatelé, kteří zpracovávají Vaše osobní údaje dle našich pokynů a vztahy s nimiž jsou
                            ošetřeny dle náležitostí článku 28 GDPR:
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Přístup k Vašim osobním údajům mohou mít poskytovatelé námi využívaných programů a to vždy
                            pouze v nezbytném rozsahu a za účelem správy a technické podpory těchto programů.
                        </Text>
                    </li>

                    <li>
                        <Text>orgány veřejné moci a jiné subjekty, jestliže to vyžaduje platný právní předpis;</Text>
                    </li>
                    <li>
                        <Text>
                            jiné subjekty v případě neočekávané události, ve které je poskytnutí údajů nezbytné za
                            účelem ochrany života, zdraví, majetku nebo jiného veřejného zájmu nebo je-li to nezbytné k
                            ochraně našich práv, majetku či bezpečnosti (např. Policie ČR).
                        </Text>
                    </li>
                </ul>

                <Heading2>8. Cookies</Heading2>
                <Text>
                    Po Vaší první návštěvě našich webových stránek odešle náš server na Váš počítač malé množství dat a
                    uloží je tam. Při každé další návštěvě stránek pak prohlížeč tato data pošle zpět serveru. Tomuto
                    malému souboru se říká „cookie“ a je to v krátký textový soubor, obsahující specifický řetěz znaků s
                    jedinečnými informacemi o Vašem prohlížeči. Cookies využíváme k tomu, abychom zlepšili kvalitu
                    našich služeb a lépe porozuměli tomu, jak lidé používají naše stránky. Proto máme v cookies uloženy
                    preference uživatelů a s jejich pomocí sledujeme uživatelské trendy a to, jak se lidé na našich
                    stránkách chovají a jakým způsobem si je prohlížejí.
                </Text>
                <Text>
                    Většina prohlížečů je nastavena tak, aby cookies přijímala. Vy ale máte možnost nastavit si
                    prohlížeč tak, aby cookies blokoval nebo aby Vás o zaslání cookies informoval. Bez cookies však
                    některé služby nebo funkce nebudou správně fungovat.
                </Text>
                <Text>
                    Naše internetové stránky využívají pouze cookies „první strany“, tedy cookies používané pouze našimi
                    webovými stránkami (dále jen cookies první strany) a cookies „třetích stran“ (tedy cookies
                    pocházejících z internetových stránek třetích stran). Cookies první strany využíváme pro ukládání
                    uživatelských preferencí a údajů, potřebných během Vaší návštěvy na internetových stránkách (např.
                    obsah Vašeho nákupního košíku). Cookies třetích stran používáme pro sledování uživatelských trendů a
                    vzorců chování, cílení reklamy a to za pomoci třetích stran - poskytovatelů webových statistik.
                    Cookies třetích stran použité pro sledování trendů a vzorců chování využívají pouze naše internetové
                    stránky a poskytovatel webových statistik, nejsou sdíleny s žádnou další třetí stranou.
                </Text>
                <Text>Využíváme zejména následující cookies třetích stran:</Text>
                <ul>
                    <li>
                        <Text>Google Analytics</Text>
                    </li>
                    <li>
                        <Text>Google AdWords</Text>
                    </li>
                    <li>
                        <Text>Facebook Pixel</Text>
                    </li>
                </ul>
                <Heading2>9. Zásady zpracování osobních údajů</Heading2>

                <Text bold>Zákonnost</Text>
                <Text>Vaše osobní údaje zpracováváme v souladu s platnými právními předpisy, zejména s GDPR.</Text>

                <Text bold>Souhlas subjektu údajů</Text>
                <Text>
                    Osobní údaje zpracováváme pouze způsobem a v rozsahu, ke kterému jste nám udělil/a souhlas, je-li
                    souhlas titulem zpracování.
                </Text>

                <Text bold>Minimalizace a omezení zpracování osobních údajů</Text>
                <Text>
                    Osobní údaje zpracováváme pouze v rozsahu, ve kterém je to nezbytné pro dosažení účelu jejich
                    zpracování, a po dobu ne delší, než je nezbytné pro dosažení účelu jejich zpracování.
                </Text>

                <Text bold>Přesnost zpracovávaných osobních údajů</Text>
                <Text>
                    Osobní údaje zpracováváme s důrazem na jejich přesnost. Za použití přiměřených prostředků
                    zpracováváme osobní údaje aktualizované.
                </Text>

                <Text bold>Transparentnost</Text>
                <Text>
                    Prostřednictvím těchto Zásad a kontaktů uvedených výše máte možnost seznámit se se způsobem, jakým
                    Vaše osobní údaje zpracováváme, jakož i s jejich rozsahem a obsahem.
                </Text>

                <Text bold>Účelové omezení</Text>
                <Text>
                    Osobní údaje zpracováváme pouze v rozsahu nezbytném pro naplnění stanoveného účelu a v souladu s
                    tímto účelem.
                </Text>

                <Text bold>Bezpečnost</Text>
                <Text>
                    Osobní údaje zpracováváme způsobem, který zajišťuje jejich náležité zabezpečení, včetně jejich
                    ochrany pomocí vhodných technických nebo organizačních opatření před neoprávněným či protiprávním
                    zpracováním a před náhodnou ztrátou, zničením nebo poškozením.
                </Text>

                <Heading2>10. Automatizované individuální rozhodování a profilování</Heading2>
                <Text>
                    Při zpracování Vašich osobních údajů <strong>nedochází</strong> k automatizovanému individuálnímu
                    rozhodování, a to ani na základě profilování.
                </Text>
                <Heading2>11. Vaše práva jako subjektu údajů</Heading2>

                <Text bold>Právo na přístup k osobním údajům</Text>
                <Text>
                    Máte právo požadovat od nás přístup k osobním údajům týkajícím se Vaší osoby. Zejména máte právo
                    získat od nás potvrzení, zda osobní údaje, které se Vás týkají, námi jsou, či nejsou zpracovávány, a
                    dále na poskytnutí dalších informací o zpracovávaných údajích a způsobu zpracování ve smyslu
                    příslušných ustanovení GDPR. V případě, že o to požádáte, poskytneme Vám kopii osobních údajů, které
                    o Vás zpracováváme, a to bezplatně. V případě opakované žádosti můžeme za poskytnutí kopie účtovat
                    přiměřený poplatek odpovídající administrativním nákladům na zpracování. Pro získání přístupu k
                    Vašim osobním údajům využijte kontaktu uvedeného v těchto Zásadách.
                </Text>

                <Text bold>
                    Právo odvolat souhlas se zpracováním osobních údajů, pokud ke zpracování dochází na základě souhlasu
                </Text>
                <Text>
                    Máte právo kdykoliv odvolat souhlas se zpracováním osobních údajů, které jsou námi na základě tohoto
                    souhlasu zpracovávány, a to zejména prostřednictvím kontaktu uvedeného v těchto Zásadách.
                </Text>

                <Text bold>Právo na opravu osobních údajů</Text>
                <Text>
                    Zjistíte-li, že námi o Vás vedené osobní údaje jsou nepřesné, můžete požadovat, abychom tyto údaje
                    bez zbytečného odkladu opravili. Je-li to s ohledem na konkrétní okolnosti případu přiměřené, můžete
                    rovněž žádat i doplnění údajů, které zpracováváme.
                    <br />
                    Opravu, omezení zpracování nebo výmaz údajů můžete požadovat prostřednictvím kontaktu uvedeného v
                    těchto Zásadách.
                </Text>

                <Text bold>Právo na výmaz osobních údajů</Text>
                <Text>
                    Máte právo požadovat, abychom bez zbytečného odkladu vymazali námi zpracovávané osobní údaje, které
                    se Vás týkají, a to v následujících případech:
                </Text>

                <ul>
                    <li>
                        <Text>
                            odvoláte-li souhlas se zpracováním osobních údajů a na naší straně není dán žádný jiný
                            oprávněný důvod pro jejich zpracování, který by převažoval nad Vaším právem na výmaz;
                        </Text>
                    </li>
                    <li>
                        <Text>vznesete-li námitku proti zpracování osobních údajů (viz níže);</Text>
                    </li>
                    <li>
                        <Text>
                            Vaše osobní údaje již nejsou potřebné pro účely, pro které jsme je shromáždili nebo jinak
                            zpracovávali;
                        </Text>
                    </li>
                    <li>
                        <Text>osobní údaje byly námi zpracovány protiprávně;</Text>
                    </li>
                    <li>
                        <Text>
                            osobní údaje byly námi shromážděny v souvislosti s nabídkou služeb informační společnosti
                            osobě mladší 18 let;
                        </Text>
                    </li>
                    <li>
                        <Text>
                            osobní údaje musí být vymazány ke splnění právní povinnosti stanovené v právu Evropské unie
                            nebo v českém právním řádu, která se na nás vztahuje. Povinnost vymazat v těchto případech
                            Vaše osobní údaje máme i bez Vaší žádosti. Za podmínek uvedených čl. 17 GDPR Vaše osobní
                            údaje vymazat nemusíme (například tehdy, pokud údaje potřebujeme pro uplatnění našich
                            právních nároků vůči Vám). Požadovat výmaz v těchto případech můžete prostřednictvím
                            kontaktu uvedeného v těchto Zásadách.
                        </Text>
                    </li>
                </ul>
                <Text>
                    Povinnost vymazat v těchto případech Vaše osobní údaje máme i bez Vaší žádosti. Za podmínek
                    uvedených čl. 17 GDPR Vaše osobní údaje vymazat nemusíme (například tehdy, pokud údaje potřebujeme
                    pro uplatnění našich právních nároků vůči Vám). Požadovat výmaz v těchto případech můžete
                    prostřednictvím kontaktu uvedeného v těchto Zásadách.
                </Text>

                <Text bold>Právo na omezení zpracování osobních údajů</Text>
                <Text>Máte právo na to, abychom omezili zpracování Vašich osobních údajů, a to v případech, kdy:</Text>
                <ul>
                    <li>
                        <Text>
                            popíráte přesnost osobních údajů. V tomto případě omezení platí na dobu potřebnou k tomu,
                            abychom mohli přesnost osobních údajů ověřit.
                        </Text>
                    </li>
                    <li>
                        <Text>
                            zpracování je protiprávní a odmítáte výmaz osobních údajů a žádáte místo toho o omezení
                            jejich použití.
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Vaše osobní údaje již nepotřebujeme pro účely, pro které jsme je zpracovávali, ale Vy je
                            požadujete pro určení, výkon nebo obhajobu právních nároků;
                        </Text>
                    </li>
                    <li>
                        <Text>
                            vznesete námitku proti zpracování (viz níže). V tomto případě omezení platí na dobu, dokud
                            nebude ověřeno, zda oprávněné důvody na naší straně převažují nad Vašimi oprávněnými důvody.
                        </Text>
                    </li>
                </ul>
                <Text>
                    V době omezení zpracování osobních údajů můžeme Vaše osobní údaje zpracovávat (s výjimkou jejich
                    uložení) pouze s Vaším souhlasem, nebo z důvodu určení, výkonu nebo obhajoby našich právních nároků,
                    z důvodu ochrany práv jiné fyzické nebo právnické osoby nebo z důvodů důležitého veřejného zájmu
                    Unie nebo některého členského státu. Jak je uvedeno výše, omezení zpracování můžete požadovat
                    prostřednictvím kontaktu uvedeného v těchto Zásadách.
                </Text>

                <Text bold>Právo vznést námitku proti zpracování</Text>
                <Text>
                    Máte právo vznést námitku proti zpracování Vašich osobních údajů, a to v následujících případech:
                </Text>
                <ul>
                    <li>
                        <Text>
                            Pokud jsou Vaše osobní údaje zpracovávány z důvodu, že je zpracování nezbytné pro účely
                            našich oprávněných zájmů, a Vy vznesete proti zpracování námitku, nemůžeme osobní údaje dále
                            zpracovávat, pokud neprokážeme závažné oprávněné důvody pro zpracování, které převažují nad
                            Vašimi zájmy, právy a svobodami, nebo pro určení, výkon nebo obhajobu našich právních
                            nároků.
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Pokud jsou Vaše osobní údaje zpracovávány pro účely přímého marketingu a vznesete proti
                            zpracování námitku, nebudeme již osobní údaje pro tyto účely zpracovávat.
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Pokud jsou Vaše osobní údaje zpracovávány pro účely vědeckého či historického výzkumu nebo
                            pro statistické účely, nebudeme je dále zpracovávat, ledaže je zpracování nezbytné pro
                            splnění úkolu prováděného z důvodů veřejného zájmu.
                        </Text>
                    </li>
                </ul>
                <Text>Námitku můžete podat prostřednictvím kontaktu uvedeného v těchto Zásadách.</Text>

                <Text bold>Právo na přenositelnost údajů</Text>
                <Text>
                    V případě, že Vaše osobní údaje zpracováváme na základě Vašeho souhlasu či z důvodu, že je to
                    nezbytné k splnění smlouvy uzavřené mezi námi, máte právo od nás získat osobní údaje, které se Vás
                    týkají, a které jste nám poskytli, a to ve strukturovaném, běžně používaném a strojově čitelném
                    formátu, jsou-li tak osobní údaje námi zpracovávány. Máte právo předat tyto údaje jinému správci
                    údajů nebo požadovat, abychom tyto údaje poskytli přímo jinému správci údajů, je-li to technicky
                    proveditelné.
                </Text>
                <Text>Pro získání Vašich osobních údajů se obraťte na kontakt uvedený v těchto Zásadách.</Text>

                <Text bold>
                    Právo nebýt předmětem žádného rozhodnutí založeného výhradně na automatizovaném zpracování, včetně
                    profilování
                </Text>
                <Text>
                    Máte právo nebýt předmětem žádného rozhodnutí založeného výhradně na automatizovaném zpracování,
                    včetně profilování, které má pro Vás právní účinky nebo se Vás obdobným způsobem významně dotýká.
                </Text>
                <Text>Toto neplatí v případě, že:</Text>
                <ul>
                    <li>
                        <Text>automatizované rozhodování umožňuje právní předpis;</Text>
                    </li>
                    <li>
                        <Text>
                            automatizované rozhodování je nezbytné k uzavření nebo plnění smlouvy uzavřené mezi námi;
                        </Text>
                    </li>
                    <li>
                        <Text>došlo k udělení Vašeho výslovného souhlasu s automatizovaným rozhodováním.</Text>
                    </li>
                </ul>

                <Text bold>Právo získat informace o porušení zabezpečení Vašich osobních údajů</Text>
                <Text>
                    Je-li pravděpodobné, že následkem porušení našeho zabezpečení bude vysoké riziko pro Vaše práva a
                    svobody, oznámíme Vám toto porušení bez zbytečného odkladu. Pokud pro zpracování Vašich osobních
                    údajů byla použita náležitá technická nebo organizační opatření zajišťující například
                    nesrozumitelnost pro neoprávněnou osobu nebo bychom dodatečnými opatřeními zajistili, že se vysoké
                    riziko neprojeví, informace o porušení Vám předávat nemusíme.
                </Text>

                <Text bold>Právo podat stížnost u dozorového úřadu</Text>
                <Text>
                    Pokud se domníváte, že zpracováním Vašich osobních údajů dochází k porušení povinností stanovených v
                    GDPR, máte právo podat stížnost u dozorového úřadu. Dozorovým orgánem v ČR je Úřad pro ochranu
                    osobních údajů.
                </Text>
                <div className={styles.tableWrapper}>
                    <table className={styles.table}>
                        <tbody>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <Text>Úřad pro ochranu osobních údajů</Text>
                                    <Text>Pplk. Sochora 27</Text>
                                    <Text>170 00 Praha 7</Text>
                                    <Text>telefon: 234 665 111</Text>
                                    <Text>
                                        E-mail: <a href="mailto:posta@uoou.cz">posta@uoou.cz</a>
                                    </Text>
                                    <Text>Datová schránka: qkbaa2n</Text>
                                    <Text>www.uoou.cz</Text>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Text bold>Tyto Zásady ochrany osobních údajů jsou účinné od 25. 5. 2018.</Text>
            </main>
        </div>
    );
};

PrivacyPolicy.propTypes = {};

export default connectFela(componentStyles)(PrivacyPolicy);
