import React from 'react';
import withPageContext from '../pageContext';
import SEO from '../components/SEO';
import HeaderScroller from '../components/HeaderScroller';
import Footer from '../components/Footer';
import { LinkType } from '../components/Link/Link.style';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';

const links = [
    { to: '/', labelId: 'home.menu.top', type: LinkType.GATSBY },
    { to: '/features', labelId: 'home.menu.features', type: LinkType.GATSBY },
    { to: '/festival', labelId: 'home.menu.festival', type: LinkType.GATSBY },
    { to: '/conference', labelId: 'home.menu.conference', type: LinkType.GATSBY },
    { to: '/#contacts', labelId: 'home.menu.contacts', asButton: true, type: LinkType.GATSBY },
];

const IndexPage = () => (
    <>
        <SEO title="title.index" desc="description.index" />
        <HeaderScroller links={links} inBlackStyle={true} hasHeader={false} />
        <PrivacyPolicy />
        <Footer />
    </>
);

export default withPageContext(IndexPage);
