export const bgWrapper = ({ theme }) => ({
    paddingTop: '12rem',
    paddingBottom: '6rem',
    backgroundColor: theme.colors.greyLight,
});

export const wrapper = ({ theme }) => ({
    maxWidth: '92rem',
    width: '100%',
    margin: '0 auto',
    '& .Text': {
        marginTop: '1rem',
    },
    '& .Heading2': {
        marginTop: '3rem',
    },
    '& bold': {
        fontWeight: 'bold',
    },
    '& ul': {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    '& li': {
        marginLeft: '2.5rem',
        paddingLeft: '1rem',
        listStyleType: 'disc',
        '& .Text': {
            paddingLeft: 0,
        },
    },
});

export const tableWrapper = ({ theme }) => ({
    marginLeft: '1rem',
    marginRight: '1rem',
    marginTop: '2rem',
    marginBottom: '6rem',
    width: 'calc(100% - 2rem)',
    overflow: 'auto',
});

export const table = ({ theme }) => ({
    borderCollapse: 'collapse',
    borderSpacing: '0',
    emptyCells: 'show',
    '& .Text': {
        fontSize: '1rem',
        lineHeight: '1.25rem',
    },
    '& td': {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        border: `1px solid ${theme.colors.grey}`,
    },
    '& tr': {
        '& td:first-child': {
            minWidth: '16rem',
        },
    },
});
